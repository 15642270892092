<template>
  <div id="app">
    <header-component />
    <router-view />
    <!-- <footer-component /> -->
  </div>
</template>

<script>
import HeaderComponent from './components/Header.vue';
// import FooterComponent from './components/Footer.vue';

export default {
  name: 'App',

  metaInfo() {
  /* Prepare meta data */
    const info = {
      title: this.$te(`${this.$route.meta.id}.title`)
        ? `${this.$t('meta.title', '-t')} - ${this.$t(`${this.$route.meta.id}.title`, '-t')}`
        : this.$t('meta.title', '-t'),

      description: this.$te(`meta.${this.$route.meta.id}.description`)
        ? this.$t(`meta.${this.$route.meta.id}.description`, '-t')
        : this.$t('meta.description', '-t'),

      author: this.$te('meta.author.name') ? this.$t('meta.author.name') : '',

      img: `${this.$t('baseurl')}img/opengraph/${this.$route.meta.id}.jpg`,
      url: `${this.$t('meta.canonical').replace(/\/$/, '')}${this.$route.path}`,
    };

    /* Alternate lang */
    const hreflang = [];
    this.$i18n.messages.translations.available.forEach((lg) => {
      if (lg !== this.$route.meta.lang) {
        hreflang.push({
          rel: 'alternate',
          href: `${this.$t('meta.canonical').replace(/\/$/, '')}/${lg}${
            this.$route.path.replace(`/${this.$route.meta.lang}`, '')}`,
          hreflang: lg,
        });
      }
    });

    /* Preload */
    const preload = []; /* array of { href: '', as: '' } */
    const mainList = this.$te('meta.preload') ? this.$t('meta.preload') : [];
    const routeList = this.$te(`meta.${this.$route.meta.id}.preload`)
      ? this.$t(`meta.${this.$route.meta.id}.preload`)
      : [];
    /* merge main (in first) and route (in priority) list */
    const preloadList = [...new Set([...mainList, ...routeList])];

    for (let i = 0; i < preloadList.length; i += 1) {
      let type = '';
      if (/\.(png|jpg|gif|webp|ico)$/.test(preloadList[i])) { type = 'image'; }
      if (/\.(ttf|eot|woff|woff2)$/.test(preloadList[i])) { type = 'font'; }
      if (/\.(webm|mp4)$/.test(preloadList[i])) { type = 'video'; }

      preload[i] = {
        href: preloadList[i].replace(/^\//, this.$t('baseurl')),
        as: type,
      };
    }

    const metaPrepared = {
      htmlAttrs: { lang: this.$route.meta.lang },
      bodyAttrs: { id: this.$route.meta.id },
      title: info.title,
      meta: [
        { name: 'description', content: info.description },
        { name: 'author', content: info.author },

        /* OpenGraph */
        { property: 'og:type', content: 'article' },
        { property: 'og:title', content: info.title },
        { property: 'og:image', content: info.img },
        { property: 'og:url', content: info.url },
        { property: 'og:description', content: info.description },

        /* Twitter */
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:description', content: info.description },
        { name: 'twitter:title', content: info.title },
        { name: 'twitter:image', content: info.img },
      ],
      script: [
        // Vince analytics
        { src: 'https://v.framasoft.org/js/script.js', defer: true, 'data-domain': 'framasoft.org' },
      ],
      link: [
        {
          rel: 'shortcut icon',
          href: `${this.$t('baseurl')}img/icons/favicon.png`,
        },
        {
          rel: 'apple-touch-icon',
          href: `${this.$t('baseurl')}img/icons/apple-touch-icon.png`,
        },
        { rel: 'canonical', href: info.url },
        { rel: 'me', href: 'https://framapiaf.org/@Framasoft' }
      ],
    };

    metaPrepared.link.push(...preload);
    metaPrepared.link.push(...hreflang);

    return metaPrepared;
  },

  components: {
    HeaderComponent,
    // FooterComponent,
  },

  data() {
    return {
      i18n: this.$t('config.mode') !== 'production' ? this.$i18n.messages : '',
    };
  },

  created() {
    if (this.$route.meta.lang !== undefined
      && this.$i18n.locale !== this.$route.meta.lang) {
      this.$i18n.locale = this.$route.meta.lang;
    }
  },
};
</script>
